import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Flex } from "uikit-react";
import { useCookies } from "react-cookie";
import moment from "moment";

import FilterPanel from "../Common/FilterPanel";
import Pagination from "../../common/Pagination";
import RecordsPerPage from "../../common/RecordsPerPage";
import { AppContexts } from "../../../providers";

const ExtendStay = () => {
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(0);
  const [cookies] = useCookies(["records-per-page"]);
  const { "records-per-page": recordsPerPage } = cookies;

  const { extendStay, startDate, endDate, getExtendStay } = useContext(
    AppContexts.ReportsContext,
  );

  const handlePageChange = useCallback((page) => setPage(page), []);
  const handleFilterChange = useCallback((filter) => setFilter(filter), []);

  useEffect(() => {
    if (!startDate || !endDate) {
      return;
    } else {
      getExtendStay(startDate, endDate, page, filter ? filter.trim() : filter);
    }
  }, [startDate, endDate, filter, page]);

  const pagedExtendStays = useMemo(
    () =>
      extendStay
        ?.sort((prev, next) => next.rated_on.localeCompare(prev.rated_on))
        ?.slice(recordsPerPage * page, recordsPerPage * (page + 1)),
    [extendStay, page, recordsPerPage],
  );

  return (
    <li className="reports-tab-content">
      <h2>Extend Stays Text Sent</h2>
      <div className="uk-card hms-left-tabs-content-card uk-padding-remove">
        <FilterPanel
          searchTermPlaceholder="Guest Name Or Property ID"
          onFilterChange={handleFilterChange}
        />
        <table className="uk-table uk-table-hover uk-table-divider hms-table uk-margin-small-top">
          <thead>
            <tr>
              <th>SENT ON</th>
              <th>EXTENSION TYPE</th>
              <th>GUEST NAME</th>
              <th>CHECK-IN DATE</th>
              <th>PROPERTY ID</th>
            </tr>
          </thead>
          <tbody>
            {pagedExtendStays?.length ? (
              pagedExtendStays?.map((item) => (
                <tr>
                  <td>{moment(item.rated_on).format("MM/DD/YYYY hh:mm A")}</td>
                  <td>{item.extension_type}</td>
                  <td>{item.guest_name}</td>
                  <td>{moment(item.check_in).format("MM/DD/YYYY")}</td>
                  <td className="reports-table-wrap-overflow">{item.pms_id}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} className="no-results">
                  No results for this date range
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <Flex alignment="between top" className="guest-form-top-filters">
        <Pagination
          count={extendStay?.length}
          index={page}
          onIndexChanged={handlePageChange}
        />
        <RecordsPerPage />
      </Flex>
    </li>
  );
};

export default ExtendStay;
