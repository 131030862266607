// Import package components
import React, {
  useEffect,
  useContext,
  useMemo,
  useState,
  useCallback,
} from "react";

import { Image, Link } from "uikit-react";
import UIkit from "uikit";
import moment from "moment";
import classnames from "classnames";
import { useHistory } from "react-router-dom";
import { message } from "antd";
import clsx from "clsx";

// Import messages components
import { AppContexts } from "../../providers/";

import Inbox from "./inbox";
import Responses from "../common/Responses";
import AddTextLine from "../common/AddTextLine";
import GuestDetails from "./guest-details";
import WorkOrderAdd from "../work_order/work_order_add";

const msgClasses = {
  automated: "rbr-msg-automated",
  user: "rbr-msg-outbound",
  recipient: "rbr-msg-outbound",
};

const Messages = () => {
  const workOrderContext = useContext(AppContexts.WorkOrderContext);
  const {
    threadDetail,
    selectedThreadId,
    selectThread,
    socket,
    sendMessage,
    loading,
    isSendingFlag,
    refresh,
    setSelectedThreadId,
    reservation,
    setThreadDetail,
  } = useContext(AppContexts.InboxContext);

  const { inbox } = useContext(AppContexts.AuthContext);

  const history = useHistory();

  const { listReservations } = useContext(AppContexts.ReservationsContext);

  const { user } = useContext(AppContexts.AuthContext);

  const { genUUID, uploadFile } = useContext(AppContexts.UtilContext);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [sendText, setSendText] = useState("");
  const [granted, setGranted] = useState(window.Notification?.permission);
  const [msgId, setMsgId] = useState(null);
  const [attachment, setAttachment] = useState(null);
  const [isInputValid, setIsInputValid] = useState(true);

  socket.on("incoming", (resp) => {
    console.log(resp);
  });

  useEffect(() => {
    setSelectedThreadId(-1);
    setThreadDetail({});
    workOrderContext.getAssigners(null);
    let threadId = +window.location.pathname.split("/")[2];

    if (!!threadId) {
      selectThread(threadId);
    }
  }, []);

  useEffect(() => {
    let msgContainer = document.querySelector(".hms-chat-panel");
    if (!!msgContainer) {
      setTimeout(() => {
        msgContainer.scrollTop = msgContainer.scrollHeight;
      }, 10);
    }
  }, [loading, selectedThreadId]);

  useEffect(() => {
    setSendText("");
  }, [selectedThreadId]);

  const { messages, guestReservations, contact } = useMemo(() => {
    return {
      messages: threadDetail?.messages || [],
      contact: threadDetail?.contact || {},
      guestReservations: (threadDetail?.guest_reservations || []).filter(
        (r) => !r.deleted,
      ),
    };
  }, [threadDetail]);

  const handleNotification = (e) => {
    if (granted === "default") {
      window.Notification?.requestPermission().then((permission) => {
        setGranted(permission);
      });
    }
  };

  const handleValidate = (e) => {
    const value = e.target.value;
    setSendText(value);
    setIsInputValid(value.trim() !== "" || !!attachment);
  };

  const handleSendMessage = async (threadId) => {
    if (sendText.trim() === "" && !attachment) {
      if (isInputValid) setIsInputValid(false);
      return;
    }
    let objTemp =
      threadId > 0
        ? {
            guest_reservation_id:
              threadDetail.guest_reservations[0]?.guest_reservation_id,
            contact_id: threadDetail.contact_id,
            thread_id: threadDetail.id,
            message: sendText,
          }
        : {
            guest_reservation_id: reservation.guest_reservation_id,
            message: sendText,
          };

    if (attachment !== null) {
      let uploadUrl = await uploadFile(
        attachment,
        `message/attachments/${genUUID()}`,
      );

      let att = {
        type: "image",
        name: attachment.name,
        original_name: attachment.name,
        url: uploadUrl,
      };

      objTemp.attachments = [att];
    }

    const result = await sendMessage(objTemp);

    if (!!result && threadId === "new") {
      history.push(`/messages/${result.id}`);
      refresh(0, "");
      selectThread(result.id);
    }

    setAttachment(null);
    setSendText("");
  };

  const handleSelectedResponse = (response) => {
    setSendText(response);
    UIkit.dropdown("#messages-response").hide(0);
  };

  const handleImage = (e) => {
    var files = e.target.files;

    if (files.length) {
      setAttachment(files[0]);
    } else {
      setAttachment(null);
    }
  };

  const alterAssetUrl = (url) => {
    return url
      ?.replace("http://uploads.ruebarue.com", "https://public.ruebarue.com")
      ?.replace(
        "s3.us-east-2.amazonaws.com/uploads.ruebarue.com",
        "public.ruebarue.com",
      );
  };

  const getSender = (msg) => {
    let { recipients } = inbox;
    let { guest_reservations, contact } = threadDetail;

    if (msg.sender_type === "automated") {
      return { last_name: "[Automated]" };
    } else if (msg.sender_type === "recipient") {
      return recipients.find((r) => r.recipient_id === msg.sender_id);
    } else if (msg.sender_type === "user") {
      return recipients.find((r) => r.user_id === msg.sender_id);
    } else if (msg.sender_type === "guest") {
      let res = guest_reservations
        .sort((a, b) => {
          return (
            moment(a.check_in, "YYYY-MM-DD").valueOf() -
            moment(b.check_in, "YYYY-MM-DD").valueOf()
          );
        })
        .slice(-1);

      return res[0] || null;
    } else if (msg.sender_type === "leads") {
      if (Array.isArray(guest_reservations) && guest_reservations.length > 0) {
        let res = guest_reservations
          .sort((a, b) => {
            return (
              moment(a.check_in, "YYYY-MM-DD").valueOf() -
              moment(b.check_in, "YYYY-MM-DD").valueOf()
            );
          })
          .slice(-1);

        return res[0] || null;
      } else {
        return contact;
      }
    } else {
      return contact;
    }
  };

  const handleAddWorkOrder = useCallback(() => {
    workOrderContext.setIsNew(true);
    workOrderContext.initWorkOrder();
  }, []);

  useEffect(() => {
    document.title = "Messages - RueBaRue";
  }, []);

  return (
    <>
      <section id="hms-message-page">
        <AddTextLine feature="Messages" />
        <div id="hms-page-title" style={{ display: "flex" }}>
          <h1 className="uk-heading-small">Messages</h1>
          <div
            className="uk-flex uk-flex-middle"
            style={{ marginLeft: "auto" }}
          >
            <div
              className="uk-margin-small-right"
              uk-tooltip="Download Mobile App"
            >
              <a
                href="https://intercom.help/ruebarueapp/en/articles/9796322-ruebarue-mobile-app"
                target="_blank"
              >
                <img src="/images/install-mobile-app.svg" />
              </a>
            </div>
            <div className="autosend-checkbox">
              <label htmlFor="enableNotification" className="setting-switch">
                <input
                  type="checkbox"
                  id="enableNotification"
                  checked={granted === "granted"}
                  disabled={granted === "denied"}
                  onChange={handleNotification}
                />
                <span className="setting-slider setting-round"></span>
              </label>
            </div>
            <div className="uk-margin-small-left">
              Desktop Notification
              <img
                className="info-icon"
                src="/images/info-icon.svg"
                uk-tooltip="Enable Desktop Notification. Go to Site Settings and set <i>Notifications</i> and <i>Sound</i> to <b>Allow</b>"
              />
            </div>
          </div>
        </div>

        <div id="hms-main-body">
          <div className="uk-overflow-auto">
            <div className="uk-flex uk-flex-top hms-chat-section">
              <Inbox />
              {(selectedThreadId > 0 || selectedThreadId === "new") && (
                <div className="uk-card uk-card-default uk-card-small uk-card-body uk-margin-left uk-width-1-2@m hms-msg-cards hms-chat-card">
                  {loading ? (
                    <div className="uk-flex uk-flex-center uk-flex-middle">
                      <span data-uk-spinner="ratio: 2;"></span>
                    </div>
                  ) : (
                    <div
                      className="hms-chat-panel"
                      uk-viewport-box="footer-el: .hms-msg-typing-box"
                    >
                      {selectedThreadId > 0 ? (
                        messages?.map((msg, index) => {
                          let sender = getSender(msg);

                          return (
                            <div
                              className={`hms-message-wrapper ${
                                msgClasses[msg.sender_type] || "rbr-msg-inbound"
                              }`}
                              key={index}
                            >
                              <div className="hms-message-content">
                                <div
                                  className={`hms-messenger-name ${msg?.sender_type}`}
                                >
                                  {sender?.first_name || ""}{" "}
                                  {sender?.last_name || "[Unknown]"}
                                </div>
                                <div
                                  className={clsx("hms-message-body", {
                                    undelivered: msg.status === "undelivered",
                                  })}
                                  onMouseEnter={() => setMsgId(msg.message_id)}
                                >
                                  <p className="hms-message-text">
                                    {msg.content}
                                  </p>
                                  <div>
                                    {msg.attachments.map(
                                      (attachment) =>
                                        attachment.type == "image" && (
                                          <Image
                                            src={alterAssetUrl(attachment.url)}
                                          />
                                        ),
                                    )}
                                  </div>
                                </div>

                                <div className="hms-message-property">
                                  <div className="status">
                                    {msg.status === "undelivered" ? (
                                      <>
                                        <img
                                          className="report-warning-icon"
                                          data-src="/images/warning.svg"
                                          data-uk-img=""
                                          loading="lazy"
                                          src="/images/warning.svg"
                                          alt="Warning Icon"
                                          data-uk-tooltip={`title: ${msg.status} - ${msg.error_code} - ${msg.error_message}`}
                                        />
                                        Undelivered
                                      </>
                                    ) : (
                                      <span></span>
                                    )}
                                  </div>
                                  <time
                                    style={{ display: "flex" }}
                                    className={`${msg.sender_type}`}
                                  >
                                    {moment
                                      .utc(msg.created_at)
                                      .local()
                                      .format("MMM DD, YYYY hh:mm A")}
                                  </time>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div>
                          There are no messages yet. Start the conversation
                        </div>
                      )}
                    </div>
                  )}
                  <div className="hms-msg-typing-box uk-margin">
                    <textarea
                      className={classnames("uk-textarea", {
                        error: !isInputValid,
                      })}
                      rows="4"
                      placeholder="Write a response"
                      onChange={handleValidate}
                      value={sendText}
                    ></textarea>
                    <div className="msg-typing-btn">
                      <div className="uk-flex uk-flex-middle">
                        <div>
                          <button
                            className="uk-button hms-btn hms-red-btn"
                            type="button"
                            onClick={() => handleSendMessage(selectedThreadId)}
                          >
                            {isSendingFlag ? "Sending ..." : "Send"}
                          </button>

                          {!!attachment && (
                            <div className="rbr-msg-attachments">
                              <div className="rbr-msg-attachment">
                                <label>{attachment.name}</label>
                                <span
                                  className="uk-close-icon uk-close-icon-flip"
                                  uk-icon="close"
                                  onClick={() => {
                                    setAttachment(null);
                                  }}
                                ></span>
                              </div>
                            </div>
                          )}
                        </div>
                        <div
                          className="msg-editing-btn"
                          style={{ display: "flex" }}
                        >
                          <Responses onChange={handleSelectedResponse} />
                          <div data-uk-form-custom>
                            <button>
                              <input
                                type="file"
                                multiple
                                onChange={handleImage}
                              />
                              <Image src="/images/photo-icon.svg" />
                            </button>
                          </div>
                          <button
                            uk-tooltip="Click to create work order for this guest"
                            uk-toggle="target: #work-order-add"
                            onClick={handleAddWorkOrder}
                          >
                            <Image src="/images/work-order-message.svg" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {selectedThreadId > 0 && (
                <GuestDetails
                  contact={contact}
                  guest_reservations={guestReservations}
                />
              )}
            </div>
          </div>
        </div>
      </section>
      <WorkOrderAdd msg_gr={guestReservations[0]} msgId={msgId} />
    </>
  );
};

export default Messages;
