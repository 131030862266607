// Import package components
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import UIkit from "uikit";
import {
  Accordion,
  AccordionItem,
  Flex,
  Image,
  InputContainer,
  Link,
} from "uikit-react";
// import { useForm } from "react-hook-form";
// import * as yup from "yup";
// import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import classnames from "classnames";

// Import base components
import { AppContexts } from "../../../providers";

// Import common components
import SMSEmailInputbox from "../../common/sms-email-inputbox";
import Tags from "../../common/Tags";
import SelectTemplate from "../../common/Template";

// const schema = yup.object().shape({
//   name: yup.string().required(),
//   body: yup.string().required(),
// });

// const keys = [
//   "name",
//   "active",
//   "lmb",
//   "send_to",
//   "message_type",
//   "tags",
//   "body",
//   "pending_balance",
//   "contract_signed",
//   "from_name",
//   "from_email",
//   "subject",
//   "reference_point",
//   "reference_direction",
//   "send_day",
//   "send_time",
//   "bcc",
//   "sources"
// ];

const SchedulerAdd = () => {
  const {
    loading,
    message,
    setMessage,
    initMessage,
    isNew,
    setIsEditing,
    insertScheduledMessage,
    updateScheduledMessage,
    sources,
  } = useContext(AppContexts.SchedulerContext);

  const { settings } = useContext(AppContexts.SettingsContext);
  const { rentals } = useContext(AppContexts.RentalsContext);
  const { user } = useContext(AppContexts.AuthContext);

  const [template, setTemplate] = useState("");
  const [msg, setMsg] = useState({});
  const [errors, setErrors] = useState([]);
  const offcanvasRef = useRef(null);
  const requiredFields = ["name", "body"];

  // const {
  //   register,
  //   handleSubmit,
  //   setValue,
  //   getValues,
  //   watch,
  //   formState: { errors },
  //   clearErrors,
  // } = useForm({
  //   resolver: yupResolver(schema),
  // });

  const doValidate = useCallback(() => {
    let errs = [];
    requiredFields.map((field) => {
      if (!msg[field].trim()) {
        errs.push(field);
      }
    });

    setErrors(errs);
    return errs.length === 0;
  }, [msg]);

  // const handleBeforeHide = (e) => {
  //   if (e.target === offcanvasRef.current) {
  //     setTemplate("");
  //     initMessage();
  //   }
  // };

  // useEffect(() => {
  //   const offcanvasElement = UIkit.offcanvas(offcanvasRef.current);
  //   const offcanvasDOMElement = offcanvasElement.$el;
  //   offcanvasDOMElement.addEventListener("beforehide", handleBeforeHide);
  //   return () => {
  //     offcanvasDOMElement.removeEventListener("beforehide", handleBeforeHide);
  //   };
  // }, []);

  useEffect(() => {
    setMsg(JSON.parse(JSON.stringify(message)));
    setErrors([]);
  }, [message]);

  // useEffect(() => {
  //   clearErrors();
  //   keys.forEach((key) => {
  //     if (key === "pending_balance") {
  //       let flag = (message.flags || []).find(
  //         (item) => item === "balance_pending" || item === "paid_in_full"
  //       );
  //       flag
  //         ? setValue("pending_balance", flag)
  //         : setValue("pending_balance", "");
  //     } else if (key === "contract_signed") {
  //       let flag = (message.flags || []).find(
  //         (item) => item === "contract_pending" || item === "contract_signed"
  //       );
  //       flag
  //         ? setValue("contract_signed", flag)
  //         : setValue("contract_signed", "");
  //     } else {
  //       setValue(key, message[key]);
  //     }
  //   });
  //   getSettings(["branding"]);
  //   if (message.sms) {
  //     setValue("delivery_type", "sms");
  //   } else if (message.email) {
  //     setValue("delivery_type", "email");
  //   }
  //   setTemplate("");
  // }, [message]);

  // const settingsBranding = useMemo(() => {
  //   if (!settings) {
  //     return {};
  //   } else {
  //     if (settings.branding) {
  //       return settings.branding;
  //     } else {
  //       return {};
  //     }
  //   }
  // }, [settings]);

  // const messageType = useMemo(
  //   () => getValues("message_type"),
  //   [watch("message_type")]
  // );
  // const deliveryType = useMemo(
  //   () => getValues("delivery_type"),
  //   [watch("delivery_type")]
  // );
  // const tags = useMemo(() => getValues("tags"), [watch("tags")]);
  // const active = useMemo(() => getValues("active"), [watch("active")]);
  // const lmb = useMemo(() => getValues("lmb"), [watch("lmb")]);
  // const body = useMemo(() => getValues("body"), [watch("body")]);
  // const sources = useMemo(() => getValues("sources"), [watch("sources")]);

  // const reference_direction = useMemo(
  //   () => getValues("reference_direction"),
  //   [watch("reference_direction")]
  // );

  // const reference_point = useMemo(
  //   () => getValues("reference_point"),
  //   [watch("reference_point")]
  // );

  // const sendDay = useMemo(() => {
  //   if (getValues("send_day") == 99998) {
  //     setValue("delivery_type", "email");
  //   }
  //   if (getValues("send_day") >= 99990 && getValues("send_day") < 99998) {
  //     if (["in", "out"].find((item) => item === reference_point)) {
  //       setValue("reference_point", "monday");
  //     }
  //   } else {
  //     if (
  //       [
  //         "monday",
  //         "tuesday",
  //         "wednesday",
  //         "thursday",
  //         "friday",
  //         "saturday",
  //         "sunday",
  //       ].find((item) => item === reference_point)
  //     ) {
  //       setValue("reference_point", "in");
  //     }
  //   }
  //   return getValues("send_day");
  // }, [watch("send_day")]);

  // const send_time = useMemo(() => getValues("send_time"), [watch("send_time")]);

  // useEffect(() => {
  //   if (
  //     deliveryType == "email" &&
  //     settingsBranding &&
  //     Object.keys(settingsBranding).length > 0
  //   ) {
  //     setValue("from_name", settingsBranding.from_name);
  //     setValue("from_email", settingsBranding.from_email);
  //   }
  // }, [deliveryType, settingsBranding]);

  const filteredRentals = useMemo(() => {
    if (!rentals) {
      return [];
    } else {
      if (Array.isArray(msg.tags) && msg.tags.length > 0) {
        return rentals.filter((r) => {
          return (
            !!r &&
            !!msg.tags.filter(
              (t1) => !!(r.tags || []).find((t2) => t1.id === t2.id),
            )[0]
          );
        });
      } else {
        return rentals.filter((r) => !!r);
      }
    }
  }, [msg.tags, rentals]);

  const handleInput = (key, e) => {
    let value = e.target.value;
    if (key === "active" || key === "lmb") {
      value = e.target.checked;
    }

    setErrors(errors.filter((k) => k !== key));

    if (key === "sms") {
      setMsg({ ...msg, sms: true, email: false });
    } else if (key === "email") {
      setMsg({
        ...msg,
        email: true,
        sms: false,
        from_name: msg.from_name || settings.branding.from_name,
        from_email: msg.from_email || settings.branding.from_email,
      });
    } else if (key === "send_day") {
      if (+value === 99998) {
        setMsg({ ...msg, send_day: value, email: true, sms: false });
      } else {
        setMsg({ ...msg, send_day: value });
      }
    } else {
      setMsg({ ...msg, [key]: value });
    }
  };

  const hasSource = (source, e) => {
    return (msg.sources || []).includes(source);
  };

  const handleSource = (e) => {
    let sources = [...(msg.sources || [])];
    let source = e.target.value;
    let idx = (msg.sources || []).indexOf(source);

    if (idx === -1) {
      sources.push(source);
    } else {
      sources.splice(idx, 1);
    }

    setMsg({ ...msg, sources: sources });
  };

  // const handleInputChange = (key, value) => {
  //   if (key === "sms" && value === true)
  //     setMessage({
  //       ...message,
  //       [key]: value,
  //       email: false,
  //     });
  //   else if (key === "email" && value === true)
  //     setMessage({
  //       ...message,
  //       [key]: value,
  //       sms: false,
  //     });
  //   else if (key === "message_type" && value === "Birthday")
  //     setMessage({
  //       ...message,
  //       [key]: value,
  //       sms: false,
  //       email: true,
  //     });
  //   else
  //     setMessage({
  //       ...message,
  //       [key]: value,
  //     });

  //   setIsEditing(true);
  // };

  // const handleClickSave = () => {
  //   if (!doValidate()) {
  //     return;
  //   }
  //   isNew === true
  //     ? insertScheduledMessage(message)
  //     : updateScheduledMessage();
  //   UIkit.modal("#scheduler-add").hide();
  //   UIkit.notification(
  //     isNew === true
  //       ? "Scheduled Message Added"
  //       : "Scheduled Message Updated",
  //     "success"
  //   );
  // };

  const handleSelectTemplate = (val) => {
    if (!val) {
      return setTemplate("");
    }

    setTemplate(val);
    setMsg({ ...msg, body: val.body, subject: val.subject });
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    let isValid = doValidate();
    if (!isValid) {
      return;
    }

    if (isNew == true) {
      let result = await insertScheduledMessage(msg);
      if (result) {
        UIkit.modal("#scheduler-add").hide();
        UIkit.notification("Scheduled Message Added", "success");
      }
    } else {
      let result = await updateScheduledMessage(msg);
      if (result) {
        UIkit.modal("#scheduler-add").hide();
        UIkit.notification("Scheduled Message Updated", "success");
      }
    }
  };

  const handleClose = () => {
    setTemplate("");
    initMessage();
  };

  const capitalize = (str) => {
    if (!str) {
      return "";
    }

    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const hasFlag = (type, value) => {
    let { flags } = msg;
    if (type === "balance" && value === "") {
      return !(flags || []).find((f) => {
        return f === "balance_pending" || f === "paid_in_full";
      });
    }

    if (type === "contract" && value === "") {
      return !(flags || []).find((f) => {
        return f === "contract_signed" || f === "contract_pending";
      });
    }

    return !!(flags || []).find((f) => {
      return f === value;
    });
  };

  const addFlag = (type, value) => {
    let flags = msg.flags || [];
    if (value === "") {
      if (type === "balance") {
        flags = (flags || []).filter(
          (f) => !["balance_pending", "paid_in_full"].includes(f),
        );
      } else if (type === "contract") {
        flags = (flags || []).filter(
          (f) => !["contract_signed", "contract_pending"].includes(f),
        );
      }
    } else if (!(flags || []).find((f) => f === value)) {
      if (type === "balance") {
        flags = (flags || []).filter(
          (f) => ["balance_pending", "paid_in_full"].indexOf(f) === -1,
        );
        flags.push(value);
      }

      if (type === "contract") {
        flags = (flags || []).filter(
          (f) => ["contract_signed", "contract_pending"].indexOf(f) === -1,
        );
        flags.push(value);
      }
    }

    setMsg({ ...msg, flags: flags });
  };
  // /**
  //  * Add Tag Name to newMessage.tags if tag is not exist in newMessage.tags array &
  //  * Remove Tag Name from newMessage.tags if tag is already exist in newMessage.tags array
  //  * @param {String} tag Tag Name
  //  */
  // /*const handleAddTag = (tag) => {
  //  if (tempMSG.tags.indexOf(tag) === -1) {
  //  schedulerCtx.setTempMSG({ ...tempMSG, tags: [...tempMSG.tags, tag] });
  //  } else {
  //  schedulerCtx.setTempMSG({
  //    ...tempMSG,
  //    tags: tempMSG.tags.filter((item) => item !== tag),
  //  });
  //  }
  // };*/

  return (
    <>
      <div
        id="scheduler-add"
        ref={offcanvasRef}
        className="uk-modal-full hms-modals uk-modal uk-open uk-dark"
        uk-offcanvas="flip: true; overlay: true"
      >
        <div className="uk-offcanvas-bar">
          <div className="hms-modals-main">
            <button
              className="uk-offcanvas-close uk-close-large"
              type="button"
              uk-close="true"
            ></button>
            <div className="uk-modal-header">
              <h2 className="uk-modal-title">
                {isNew === true ? "Add a" : "Edit"} Scheduled Message
              </h2>
            </div>
            <div className="uk-modal-body uk-flex">
              <div className="addScheduleMsgForm">
                <form
                  className="uk-grid-small uk-margin-none-top"
                  uk-grid="true"
                  onSubmit={handleRegister}
                >
                  <div className="uk-width-1-2">
                    <label
                      className="uk-form-label"
                      htmlFor="txt-schedule-name"
                    >
                      Name*
                    </label>
                    <InputContainer>
                      <input
                        className={classnames("uk-input", {
                          error: !!errors.includes("name"),
                        })}
                        id="txt-schedule-name"
                        value={msg.name}
                        onInput={handleInput.bind(null, "name")}
                      />
                    </InputContainer>
                  </div>
                  <div className="uk-width-1-2">
                    <div className="uk-form-label uk-text-center">Active</div>
                    <div className="autosend-checkbox">
                      <label
                        htmlFor="scheduleNameActive"
                        className="setting-switch"
                      >
                        <input
                          type="checkbox"
                          className="uikit-checkbox"
                          id="scheduleNameActive"
                          checked={msg.active || false}
                          onChange={handleInput.bind(null, "active")}
                        />
                        <span className="setting-slider setting-round"></span>
                      </label>
                    </div>
                  </div>
                  <SelectTemplate
                    onChange={handleSelectTemplate}
                    template={template}
                  />
                  <div className="uk-width-1-1">
                    <div className="hms-add-tag">
                      <div className="uk-form-label">Tags</div>
                      <div className="uk-flex uk-flex-top">
                        <Tags
                          value={msg.tags || []}
                          onChange={(tags) =>
                            handleInput("tags", { target: { value: tags } })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-1-1">
                    <div className="uk-form-label">Send By</div>
                    <div className="uk-grid uk-form-controls">
                      <label className="uk-margin-right">
                        <input
                          type="radio"
                          className="uk-radio"
                          name="send-type-radio"
                          checked={msg.sms}
                          onChange={handleInput.bind(null, "sms")}
                          disabled={Math.abs(msg.send_day) == "99998"}
                        />{" "}
                        SMS
                      </label>
                      <label>
                        <input
                          type="radio"
                          className="uk-radio"
                          name="send-type-radio"
                          checked={msg.email}
                          onChange={handleInput.bind(null, "email")}
                        />{" "}
                        Email
                      </label>
                    </div>
                  </div>
                  <>
                    <div className="uk-width-1-3@s uk-margin-small-right">
                      <label className="uk-form-label">Schedule</label>
                      <div className="uk-form-controls">
                        <div uk-form-custom="target: > * > span:first-child">
                          <select
                            value={Math.abs(msg.send_day)}
                            onInput={handleInput.bind(null, "send_day")}
                          >
                            <optgroup label="Custom" />
                            {[...Array(2)].map((_, index) => {
                              if (index === 0)
                                return (
                                  <option
                                    value={-99999}
                                    key={`custom_${index}`}
                                  >
                                    Do Not Send
                                  </option>
                                );
                              if (index === 1)
                                return (
                                  <option value={99998} key={`custom_${index}`}>
                                    Email on Booking
                                  </option>
                                );
                            })}
                            <optgroup label="Day of Week" />
                            {[...Array(8)].map((_, index) => (
                              <option
                                value={99997 - index}
                                key={`week_${index}`}
                              >
                                {index} Day(s) Before
                              </option>
                            ))}
                            <optgroup label="Check-In/Check-Out" />
                            {[...Array(30)].map((_, index) => {
                              if (index === 0) {
                                return (
                                  <option value={0} key={`relative_${index}`}>
                                    Day of
                                  </option>
                                );
                              }
                              if (index === 1) {
                                return (
                                  <option
                                    value={index}
                                    key={`relative_${index}`}
                                  >
                                    {index} Day
                                  </option>
                                );
                              }
                              return (
                                <option value={index} key={`relative_${index}`}>
                                  {index} Days
                                </option>
                              );
                            })}
                          </select>
                          <button
                            className="uk-button uk-button-default"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                            type="button"
                            tabIndex="-1"
                          >
                            <span>
                              {Math.abs(msg.send_day) === 99999
                                ? "Do Not Send"
                                : Math.abs(msg.send_day) === 99998
                                  ? "Email Upon Booking"
                                  : Math.abs(msg.send_day) >= 99990 &&
                                      Math.abs(msg.send_day) < 99998
                                    ? `${
                                        99997 - Math.abs(msg.send_day)
                                      } Day(s) Before`
                                    : Math.abs(msg.send_day) === 1
                                      ? `${Math.abs(msg.send_day)} Day`
                                      : Math.abs(msg.send_day) === 0
                                        ? "Day of"
                                        : `${Math.abs(msg.send_day)} Days`}
                            </span>
                            <span
                              uk-icon="icon: chevron-down"
                              className="hms-align-right"
                            ></span>
                          </button>
                        </div>
                      </div>
                    </div>
                    {Math.abs(msg.send_day) >= 0 ? (
                      <>
                        {Math.abs(msg.send_day) > 0 &&
                          Math.abs(msg.send_day) < 99990 && (
                            <div className="uk-width-1-3@s uk-margin-small-right">
                              <label className="uk-form-label">
                                Before/After
                              </label>
                              <div className="uk-form-controls">
                                <div uk-form-custom="target: > * > span:first-child">
                                  <select
                                    value={msg.reference_direction}
                                    onInput={handleInput.bind(
                                      null,
                                      "reference_direction",
                                    )}
                                  >
                                    <option value="before">Before</option>
                                    <option value="after">After</option>
                                  </select>
                                  <button
                                    className="uk-button uk-button-default"
                                    type="button"
                                    tabIndex="-1"
                                  >
                                    <span>
                                      {msg.reference_direction
                                        ?.charAt(0)
                                        .toUpperCase() +
                                        msg.reference_direction?.slice(1)}
                                    </span>
                                    <span
                                      uk-icon="icon: chevron-down"
                                      className="hms-align-right"
                                    ></span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        {Math.abs(msg.send_day) >= 99990 &&
                        Math.abs(msg.send_day) < 99998 ? (
                          <>
                            <div className="uk-width-1-3@s uk-margin-small-right">
                              <label className="uk-form-label">On</label>
                              <div className="uk-form-controls">
                                <div uk-form-custom="target: > * > span:first-child">
                                  <select
                                    value={msg.reference_point}
                                    onInput={handleInput.bind(
                                      null,
                                      "reference_point",
                                    )}
                                  >
                                    <option value="monday">Monday</option>
                                    <option value="tuesday">Tuesday</option>
                                    <option value="wednesday">Wednesday</option>
                                    <option value="thursday">Thursday</option>
                                    <option value="friday">Friday</option>
                                    <option value="saturday">Saturday</option>
                                    <option value="sunday">Sunday</option>
                                  </select>
                                  <button
                                    className="uk-button uk-button-default"
                                    type="button"
                                    tabIndex="-1"
                                  >
                                    <span>
                                      {capitalize(msg.reference_point)}
                                    </span>
                                    <span
                                      uk-icon="icon: chevron-down"
                                      className="hms-align-right"
                                    ></span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="uk-width-1-3@s uk-margin-small-right">
                              <label className="uk-form-label">At</label>
                              <div className="uk-form-controls">
                                <div uk-form-custom="target: > * > span:first-child">
                                  <select
                                    value={msg.send_time}
                                    onInput={handleInput.bind(
                                      null,
                                      "send_time",
                                    )}
                                  >
                                    <option value="">Select a Time</option>
                                    {[...Array(24 * 4)].map((_, index) => {
                                      var strTime = new Date(
                                        0,
                                        0,
                                        0,
                                        index / 4,
                                        (index % 4) * 15,
                                      ).toLocaleString("en-US", {
                                        hour: "numeric",
                                        minute: "2-digit",
                                        hour12: true,
                                      });
                                      return (
                                        <option
                                          value={strTime}
                                          key={`time_${index}`}
                                        >
                                          {strTime}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  <button
                                    className="uk-button uk-button-default"
                                    type="button"
                                    tabIndex="-1"
                                  >
                                    <span>{msg.send_time}</span>
                                    <span
                                      uk-icon="icon: chevron-down"
                                      className="hms-align-right"
                                    ></span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          Math.abs(msg.send_day) !== 99998 &&
                          Math.abs(msg.send_day) !== 99999 && (
                            <>
                              <div className="uk-width-1-3@s uk-margin-small-right">
                                <label className="uk-form-label">
                                  Check-In/Out
                                </label>
                                <div className="uk-form-controls">
                                  <div uk-form-custom="target: > * > span:first-child">
                                    <select
                                      value={msg.reference_point}
                                      onInput={handleInput.bind(
                                        null,
                                        "reference_point",
                                      )}
                                    >
                                      <option value="in">Check-In</option>
                                      <option value="out">Check-Out</option>
                                    </select>
                                    <button
                                      className="uk-button uk-button-default"
                                      type="button"
                                      tabIndex="-1"
                                    >
                                      <span>{`Check-${capitalize(
                                        msg.reference_point,
                                      )}`}</span>
                                      <span
                                        uk-icon="icon: chevron-down"
                                        className="hms-align-right"
                                      ></span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="uk-width-1-3@s uk-margin-small-right">
                                <label className="uk-form-label">At</label>
                                <div className="uk-form-controls">
                                  <div uk-form-custom="target: > * > span:first-child">
                                    <select
                                      value={msg.send_time}
                                      onInput={handleInput.bind(
                                        null,
                                        "send_time",
                                      )}
                                    >
                                      <option value="">Select a Time</option>
                                      {[...Array(24 * 4)].map((_, index) => {
                                        var strTime = new Date(
                                          0,
                                          0,
                                          0,
                                          index / 4,
                                          (index % 4) * 15,
                                        ).toLocaleString("en-US", {
                                          hour: "numeric",
                                          minute: "2-digit",
                                          hour12: true,
                                        });
                                        return (
                                          <option
                                            value={strTime}
                                            key={`time_${index}`}
                                          >
                                            {strTime}
                                          </option>
                                        );
                                      })}
                                    </select>
                                    <button
                                      className="uk-button uk-button-default"
                                      type="button"
                                      tabIndex="-1"
                                    >
                                      <span>{msg.send_time}</span>
                                      <span
                                        uk-icon="icon: chevron-down"
                                        className="hms-align-right"
                                      ></span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </>
                          )
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </>
                  {msg.email && (
                    <>
                      <div className="uk-width-1-2">
                        <label
                          className="uk-form-label"
                          htmlFor="txt-schedule-name"
                        >
                          From Name
                        </label>
                        <div className="uk-form-controls">
                          <input
                            className="uk-input"
                            id="txt-from-name"
                            type="text"
                            value={msg.from_name}
                            onInput={handleInput.bind(null, "from_name")}
                          />
                        </div>
                      </div>
                      <div className="uk-width-1-2">
                        <label
                          className="uk-form-label"
                          htmlFor="txt-schedule-name"
                        >
                          From Email Address
                        </label>
                        <div className="uk-form-controls">
                          <input
                            className="uk-input"
                            id="txt-from-name"
                            type="text"
                            placeholder=""
                            value={msg.from_email}
                            onInput={handleInput.bind(null, "from_email")}
                          />
                        </div>
                      </div>
                      <div className="uk-width-1-2">
                        <label
                          className="uk-form-label"
                          htmlFor="txt-schedule-name"
                        >
                          Subject
                        </label>
                        <div className="uk-form-controls">
                          <input
                            className="uk-input"
                            id="txt-from-name"
                            type="text"
                            placeholder=""
                            value={msg.subject}
                            onInput={handleInput.bind(null, "subject")}
                          />
                        </div>
                      </div>
                      <div className="uk-width-1-2">
                        <label
                          className="uk-form-label"
                          htmlFor="txt-schedule-name"
                        >
                          BCC Email
                        </label>
                        <div className="uk-form-controls">
                          <input
                            className="uk-input"
                            id="txt-from-name"
                            type="text"
                            placeholder=""
                            value={msg.bcc}
                            onInput={handleInput.bind(null, "bcc")}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  <div className="uk-width-1-1">
                    <SMSEmailInputbox
                      error={errors.includes("body")}
                      value={msg.body || ""}
                      limit={msg.sms}
                      macrosOrResponse={true}
                      onChange={handleInput.bind(null, "body")}
                    />
                  </div>
                  <ul
                    uk-accordion="multiple: true"
                    className="scheduler-advanced-options"
                  >
                    <li>
                      <div className="uk-accordion-title" href>
                        Booking Sources
                      </div>
                      <div className="uk-accordion-content">
                        <p className="secondary">
                          Choose the booking sources you want to send to. If you
                          don't choose any, it will be sent to all booking
                          sources.
                        </p>
                        <section
                          id="source-restrictions"
                          className="uk-width-1-1"
                        >
                          <div className="uk-width-1-1">
                            <div className="uk-form-controls">
                              <div className="uk-column-1-3">
                                {(sources || []).map((source, idx) => {
                                  return (
                                    <div className="uk-form-controls">
                                      <input
                                        className="uk-checkbox"
                                        id={`source-${idx}`}
                                        type="checkbox"
                                        name="sources"
                                        placeholder=""
                                        checked={hasSource(source)}
                                        onInput={handleSource}
                                        value={source}
                                      />
                                      <label
                                        className="uk-label"
                                        for={`source-${idx}`}
                                      >
                                        {source}
                                      </label>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </li>
                  </ul>
                  {(user?.details?.pms === "Streamline" ||
                    user?.details?.pms === "LiveRez" ||
                    user?.details?.pms === "Hostaway") && (
                    <ul
                      uk-accordion="multiple: true"
                      className="scheduler-advanced-options"
                    >
                      <li>
                        <div className="uk-accordion-title" href>
                          Advanced Options
                        </div>
                        <div className="uk-accordion-content">
                          <div className="uk-width-1-1 uk-margin-top">
                            <div className="uk-width-1-1 balance-contract-radios">
                              <div className="uk-flex uk-flex-between">
                                <div className="uk-width-1-2">
                                  <div className="uk-form-label">
                                    Pending Balance*
                                  </div>
                                  <div className="uk-form-controls">
                                    <div className="uk-flex">
                                      <label>
                                        <input
                                          type="radio"
                                          className="uk-radio"
                                          name="send-balance-radio"
                                          checked={hasFlag("balance", "")}
                                          onChange={addFlag.bind(
                                            null,
                                            "balance",
                                            "",
                                          )}
                                        />
                                      </label>
                                      <div>Ignore balance</div>
                                    </div>
                                    <div className="uk-flex">
                                      <label>
                                        <input
                                          type="radio"
                                          className="uk-radio"
                                          name="send-balance-radio"
                                          checked={hasFlag(
                                            "balance",
                                            "balance_pending",
                                          )}
                                          onChange={addFlag.bind(
                                            null,
                                            "balance",
                                            "balance_pending",
                                          )}
                                        />
                                      </label>
                                      <span>Pending balance</span>
                                    </div>
                                    <div className="uk-flex">
                                      <label>
                                        <input
                                          type="radio"
                                          className="uk-radio"
                                          name="send-balance-radio"
                                          checked={hasFlag(
                                            "balance",
                                            "paid_in_full",
                                          )}
                                          onChange={addFlag.bind(
                                            null,
                                            "balance",
                                            "paid_in_full",
                                          )}
                                        />
                                      </label>
                                      <div>Paid in full</div>
                                    </div>
                                  </div>
                                </div>
                                <div className="uk-width-1-2">
                                  <div className="uk-form-label">
                                    Contract Signed*
                                  </div>
                                  <div className="uk-form-controls">
                                    <div className="uk-flex">
                                      <label>
                                        <input
                                          type="radio"
                                          className="uk-radio"
                                          name="send-contract-radio"
                                          checked={hasFlag("contract", "")}
                                          onChange={addFlag.bind(
                                            null,
                                            "contract",
                                            "",
                                          )}
                                        />
                                      </label>
                                      <div>Ignore contract signed</div>
                                    </div>
                                    <div className="uk-flex">
                                      <label>
                                        <input
                                          type="radio"
                                          className="uk-radio"
                                          name="send-contract-radio"
                                          checked={hasFlag(
                                            "contract",
                                            "contract_pending",
                                          )}
                                          onChange={addFlag.bind(
                                            null,
                                            "contract",
                                            "contract_pending",
                                          )}
                                        />
                                      </label>
                                      <div>Has not signed the contract</div>
                                    </div>
                                    <div className="uk-flex">
                                      <label>
                                        <input
                                          type="radio"
                                          className="uk-radio"
                                          name="send-contract-radio"
                                          checked={hasFlag(
                                            "contract",
                                            "contract_signed",
                                          )}
                                          onChange={addFlag.bind(
                                            null,
                                            "contract",
                                            "contract_signed",
                                          )}
                                        />
                                      </label>
                                      <div>Signed the contract</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <p className="secondary uk-margin-top">
                              * Airbnb bookings are excluded from these rules.
                              Contact RueBaRue Support to override this setting.
                            </p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  )}

                  {!(msg.send_day <= 99997 && msg.send_day >= 99990) && (
                    <div className="uk-width-1-1 uk-margin-top">
                      <div className="uk-flex">
                        <div className="autosend-checkbox uk-form-controls">
                          <label
                            htmlFor="lastMinuteBooking"
                            className="setting-switch"
                          >
                            <input
                              type="checkbox"
                              id="lastMinuteBooking"
                              checked={msg.lmb || false}
                              onChange={handleInput.bind(null, "lmb")}
                            />
                            <span className="setting-slider setting-round"></span>
                          </label>
                        </div>
                        <div className="m-l-10">
                          <div>
                            Include in Last Minute Bookings{" "}
                            <a href="/settings/messages">
                              Messaging Hours{" "}
                              <img
                                className="info-icon"
                                src="/images/info-icon.svg"
                                alt="info"
                                uk-tooltip="This message will be sent to guests who made last minute within the messaging hours. See Settings > Messages > Messaging Hour."
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div>
                    <button
                      className="uk-button hms-btn hms-red-btn"
                      type="submit"
                    >
                      {loading == true ? (
                        <div data-uk-spinner="ratio: 0.5;"></div>
                      ) : (
                        "Save"
                      )}
                    </button>
                    &nbsp;
                    <button
                      className="uk-button hms-btn uk-offcanvas-close"
                      onClick={handleClose}
                      type="button"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
                <div className="s-msg-last-updated">
                  {isNew === false
                    ? "Last Updated: " +
                      moment(message?.updated_at).format("MM/DD/YYYY hh:mm a")
                    : ""}
                </div>
              </div>
              <div className="schedule-modal-right">
                <div className="schedule-modal-right-inner">
                  <div>
                    Message will be sent to guests staying at these{" "}
                    <b>{filteredRentals.length} properties</b>.
                  </div>
                  <div className="properties-w-home-icon">
                    {filteredRentals?.map(
                      (rental, index) =>
                        rental && (
                          <div
                            className="uk-flex uk-flex-middle"
                            key={`scheduler_rental_${index}`}
                          >
                            <div>
                              <img
                                src="/images/home.svg"
                                alt="home"
                                width="30px"
                                height="30px"
                              />
                            </div>
                            <div uk-tooltip={rental.pms_id}>
                              {rental.pms_id?.length > 30
                                ? `${rental.pms_id.slice(0, 30)}...`
                                : rental.pms_id}
                            </div>
                          </div>
                        ),
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SchedulerAdd;
