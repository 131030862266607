import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { Image } from "uikit-react";
import UIkit from "uikit";
import { CSVLink } from "react-csv";

import { AppContexts } from "../../../providers";
import { Link } from "../../../ui";
import TableLoading from "../../common/Loading/table_loading";

const GuestDetail = () => {
  const {
    reservation,
    historyReservation,
    guestBook,
    flagIsLoading,
  } = useContext(AppContexts.ReservationsContext);
  const { threadDetail } = useContext(AppContexts.InboxContext);

  const [isMsg, setTextMsg] = useState(false);

  const headers = [
    { label: "SENT AT", key: "created_at" },
    { label: "SENT BY", key: "sender_type" },
    { label: "MESSAGE", key: "content" },
  ];

  useEffect(() => {
    UIkit.util.on("#guest-detail", "shown", function (e) {
      UIkit.util.index(e.target) == 1 ? setTextMsg(true) : setTextMsg(false);
    });
  }, []);

  const reservations = useMemo(
    () =>
      reservation.thread_id
        ? threadDetail.guest_reservations
        : historyReservation,
    [threadDetail, historyReservation],
  );

  const messages = useMemo(() => {
    if (reservation.thread_id) {
      return threadDetail?.messages;
    }
    return [];
  }, [threadDetail]);

  const sorted_messages = useMemo(
    () =>
      messages?.sort((prev, next) =>
        next.created_at.localeCompare(prev.created_at),
      ),
    [messages],
  );
  const reservationRenderer = (reservation, index) => {
    return (
      <tr key={`reservation_${index}}`}>
        <td>{`${moment(reservation?.check_in)
          .utc()
          .format("MMM DD, YYYY")} - ${moment(reservation?.check_out)
          .utc()
          .format("MMM DD, YYYY")}`}</td>
        <td>{reservation?.pms_id}</td>
      </tr>
    );
  };

  const messageRenderer = (message, index) => {
    return (
      <tr key={`message_${index}`}>
        <td>
          {message.status === "undelivered" ? (
            <>
              <img
                className="report-warning-icon"
                data-src="/images/warning.svg"
                data-uk-img=""
                loading="lazy"
                src="/images/warning.svg"
                alt="Warning Icon"
                data-uk-tooltip={`title: ${message.status} - ${message.error_code} - ${message.error_message}`}
              />
            </>
          ) : (
            <span></span>
          )}
          {moment.utc(message.created_at).local().format("MM/DD/YYYY hh:mm A")}
        </td>
        <td>{message.sender_type}</td>
        <td>{message.content}</td>
      </tr>
    );
  };

  const guestBookRenderer = (guestBook, index) => {
    return (
      <tr key={`guestBook_${index}`}>
        <td>{moment(guestBook.viewed_at).format("MM/DD/YYYY hh:mm A")}</td>
        <td>{guestBook.property_pms_id}</td>
        <td>
          <a href={`rental/${guestBook.property_id}`}>
            {guestBook.property_name}
          </a>
        </td>
      </tr>
    );
  };

  return (
    <div
      id="modal-guest-detail"
      className="uk-modal-full hms-modals uk-modal uk-open uk-dark"
      uk-offcanvas="flip: true; overlay: true"
    >
      <div className="uk-offcanvas-bar">
        <div className="hms-modals-main">
          <button
            className="uk-offcanvas-close uk-close-large"
            type="button"
            data-uk-close
          />
          <div className="uk-modal-header">
            <span className="uk-modal-title">{reservation?.name}</span>
            <span className="uk-margin-small-left">
              {reservation?.formatted_phone}
            </span>
          </div>
          <div className="uk-modal-body">
            <div className="detail-header">
              <ul
                data-uk-tab
                uk-tab="connect: #guest-detail; animation: uk-animation-fade"
                className="uk-margin-small-bottom uk-margin-small-top"
              >
                <li className="uk-active">
                  <Link href="#">STAYS</Link>
                </li>
                <li>
                  <Link href="#">TEXT MESSAGE LOGS</Link>
                </li>
                <li>
                  <Link href="#">GUESTBOOK VIEWS</Link>
                </li>
              </ul>

              {isMsg && (sorted_messages || []).length > 0 && (
                <CSVLink
                  data={sorted_messages}
                  filename={`${moment().unix()}_msg_logs.csv`}
                  headers={headers}
                >
                  <button className="download">
                    <Image
                      src="/images/download.svg"
                      uk-tooltip="Download CSV"
                    />
                  </button>
                </CSVLink>
              )}
            </div>
            <div>
              <ul id="guest-detail" className="uk-switcher">
                <div className="guest-stays">
                  <table className="uk-table uk-table-hover uk-table-divider hms-table">
                    <thead>
                      <tr>
                        <th>CHECK-IN & OUT</th>
                        <th>PROPERTY ID</th>
                      </tr>
                    </thead>
                    <tbody>
                      {flagIsLoading ? (
                        TableLoading(2)
                      ) : (reservations || []).length ? (
                        (reservations || []).map(reservationRenderer)
                      ) : (
                        <tr>
                          <td colSpan={2} className="uk-text-center">
                            No reservations found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="guest-messages">
                  <table className="uk-table uk-table-hover uk-table-divider hms-table">
                    <thead>
                      <tr>
                        <th>SENT AT</th>
                        <th>SENT BY</th>
                        <th>MESSAGE</th>
                      </tr>
                    </thead>
                    <tbody>
                      {flagIsLoading ? (
                        TableLoading(3)
                      ) : (sorted_messages || []).length ? (
                        (sorted_messages || []).map(messageRenderer)
                      ) : (
                        <tr>
                          <td colSpan={3} className="uk-text-center">
                            No text messages sent or received
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="guest-bookview">
                  <table className="uk-table uk-table-hover uk-table-divider hms-table">
                    <thead>
                      <tr>
                        <th>VIEWED AT</th>
                        <th>PROPERTY ID</th>
                        <th>HOME GUIDE</th>
                      </tr>
                    </thead>
                    <tbody>
                      {flagIsLoading ? (
                        TableLoading(3)
                      ) : (guestBook || []).length ? (
                        (guestBook || []).map(guestBookRenderer)
                      ) : (
                        <tr>
                          <td colSpan={3} className="uk-text-center">
                            No Guestbook Views
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuestDetail;
