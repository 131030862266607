import React, {
  forwardRef,
  useState,
  useEffect,
  useContext,
  useMemo,
} from "react";
import { useDebounce } from "../../../hook";
import { AppContexts } from "../../../providers";
import { AutoComplete } from "antd";
import "antd/dist/antd.css";

const GoogleAutoComplete = forwardRef(
  (
    {
      onSelect,
      width,
      className,
      placeholder = "",
      address,
      location,
      onChange,
    },
    ref,
  ) => {
    const [searchTerm, setSearchTerm] = useState(null);
    const debounceSearchTerm = useDebounce(searchTerm, 300);
    const UtilContext = useContext(AppContexts.UtilContext);

    useEffect(() => {
      if (debounceSearchTerm) {
        UtilContext.autoComplete(debounceSearchTerm, location);
      }
    }, [debounceSearchTerm]);

    const predictions = useMemo(() => {
      return UtilContext.predictions?.map((prediction) => {
        let temp = { value: prediction.description, text: prediction.place_id };
        return temp;
      });
    }, [UtilContext.predictions]);

    return (
      <AutoComplete
        style={{
          width,
        }}
        placeholder={placeholder}
        options={predictions}
        filterOption={(inputValue, option) => {
          return true;
        }}
        allowClear
        onSelect={(value, option) => onSelect(value, option)}
        onSearch={(value) => setSearchTerm(value)}
        onChange={(e) => onChange(e)}
        value={address}
      >
        <input className={className} />
      </AutoComplete>
    );
  },
);

export default GoogleAutoComplete;
